<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th style="width: 140px" class="text-center">
              <SelectFilter
                :label="$t('labels.certification_mark')"
                :placeholder="$t('labels.certification_mark')"
                name="file_path"
                sort-name="file_path"
                :options="statusOptions"
              />
            </th>
            <th style="width: 140px" class="text-center">
              <SelectFilter
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="type"
                sort-name="type"
                :options="typeOptions"
              />
            </th>
            <th style="width: 140px" class="text-center">
              <InputFilter
                :label="$t('labels.code_profile')"
                :placeholder="$t('labels.code_profile')"
                name="code"
                sort-name="code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 160px">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.enterprise')"
                :placeholder="$t('labels.enterprise')"
                name="company"
                sort-name="company"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.address')"
                :placeholder="$t('labels.address')"
                name="address"
                sort-name="address"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 120px">
              <DateRangeFilter
                :label="$t('labels.token_status_0')"
                :placeholder="$t('labels.token_status_0')"
                name="expired_date"
                sort-name="expired_date"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 120px">
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                :options="statusOptions"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="width: 100px">
              <v-btn
                small
                color="primary"
                @click="addItem"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`cf_${index}_${item.id}`"
            class="text-center"
          >
            <td>
              <div>
                <ImageViewer
                  :url="item.file_path"
                  v-if="item.file_path"
                  width="70px"
                  height="auto"
                />
              </div>
              <div class="pb-1" v-if="item.editing">
                <v-btn
                  x-small
                  outlined
                  color="warning"
                  @click="showUploadFile(item)"
                  >{{ $t("labels.upload") }}</v-btn
                >
              </div>
            </td>
            <td>
              <v-autocomplete
                v-model.number="item.type"
                :disabled="!item.editing"
                :items="typeOptions"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.classify')"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </td>
            <td>
              <v-text-field
                v-model="item.code"
                :disabled="!item.editing || !!item.id"
                :placeholder="$t('labels.code_profile')"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="item.name"
                :disabled="!item.editing"
                :placeholder="$t('labels.name')"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="item.company"
                :disabled="!item.editing"
                :placeholder="$t('labels.supplier_type_1')"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="item.address"
                :disabled="!item.editing"
                :placeholder="$t('labels.address')"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <date-picker-model
                v-model="item.expired_date"
                :disabled="!item.editing"
                :placeholder="$t('labels.token_status_0')"
                custom-class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
                :min-date="currentDate"
              ></date-picker-model>
            </td>
            <td class="text-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="py-1">
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                  >{{ $t("labels.edit") }}</v-btn
                >
              </template>
              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="saveItem(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        v-if="totalPage > 1"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>

    <input
      type="file"
      ref="inputUploadFile"
      accept="image/svg+xml"
      @change="onInputFileChange"
      class="d-none"
    />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { STATUS_OPTIONS, PROFILE_TYPE_OPTIONS } from "@/libs/const";
import moment from "moment/moment";

export default {
  name: "ListConformity",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    InputFilter: () => import("@/components/table/InputFilter"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    totalPage: 1,
    page: 1,
    uploadingItem: {},
    filters: {},
    statusOptions: [...STATUS_OPTIONS],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    typeOptions() {
      return [...PROFILE_TYPE_OPTIONS].map((t) => ({
        text: this.$t(`labels.profile_type_${t}`),
        value: t,
      }));
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    getList: debounce(function () {
      httpClient
        .post("/conformity-profile-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((r) => ({ ...r, editing: false }));
        });
    }, 500),
    async saveItem(item) {
      if (!item.code || item.code.length > 8) {
        this.$vToastify.warning(
          this.$t("messages.confirmity_code_is_not_empty")
        );
        return false;
      }
      if (!item.name || !item.company || !item.address || !item.type) {
        this.$vToastify.warning(this.$t("messages.confirmity_other_validate"));
        return false;
      }
      if (
        [1, 2].includes(item.type) &&
        (!item.file_path || !item.expired_date)
      ) {
        this.$vToastify.warning("Dấu chứng nhân & HSD không được để trống");
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-conformity-profile", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addItem() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        name: null,
        file_path: null,
        company: null,
        address: null,
        expired_date: null,
        status: 1,
        editing: true,
      });
    },

    showUploadFile(item) {
      this.uploadingItem = { ...item };
      this.$refs.inputUploadFile.click();
    },
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      if (files && files[0]) {
        const filePath = await this.uploadFile(files[0]);
        if (filePath) {
          const itemIndex = [...this.items].findIndex(
            (item) => item.id === this.uploadingItem.id
          );
          const items = [...this.items];
          items[itemIndex] = {
            ...this.uploadingItem,
            file_path: `${process.env.VUE_APP_FILE_CDN_URL}/${filePath}`,
          };
          this.items = [...items];
        }
        this.$refs.inputUploadFile.value = null;
      }
      this.isLoading = false;
    },
    async uploadFile(file) {
      if (file.type !== "image/svg+xml") {
        this.$vToastify.error(this.$t("messages.image_svg_only"));
        return false;
      }
      let fd = new FormData();
      fd.append("file", file);

      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },
  },
};
</script>

<style scoped></style>
